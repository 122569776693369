import React from "react"
import Article from "../components/Article"
import ImageWithTitle from "../components/ImageWithTitle"
import { graphql } from "gatsby"
import ImageGrid from "../components/ImageGrid"
import i18nHOC from "../components/i18nHOC"
import Paragraph from "../components/Paragraph"
import SinglePortraitImageWithTitle from "../components/SinglePortraitImageWithTitle"

function blackLocust({ data, t }) {
  return <Article coverImage={{ image: data.coverImage, title: t("items.blackLocust.coverCap") }}
                  title={t("items.blackLocust.title")}>
    <Paragraph>{t("items.blackLocust.p1")}</Paragraph>
    <ImageGrid>
      <ImageWithTitle fluid={data.grid1_1.childImageSharp.fluid} />
      <ImageWithTitle fluid={data.grid1_2.childImageSharp.fluid} title={t("items.blackLocust.cap1")}/>
    </ImageGrid>
    <Paragraph>{t("items.blackLocust.p2")}</Paragraph>
    <SinglePortraitImageWithTitle fluid={data.blackLocust1.childImageSharp.fluid}
                                  title={t("items.blackLocust.cap2")} />
    <ImageWithTitle fluid={data.blackLocust2.childImageSharp.fluid} title={t("items.blackLocust.cap3")}/>
  </Article>
}

export default i18nHOC(blackLocust)

export const query = graphql`
  query {
    coverImage: file(relativePath: { eq: "black-locust/DSC_2544 (1).JPG" }) {
        ...CoverImage
    },
    grid1_1: file(relativePath: { eq: "black-locust/DSC_0045.jpg" }) {
      ...FluidArticleImage
    },
    grid1_2: file(relativePath: { eq: "black-locust/DSC_0059.jpg" }) {
      ...FluidArticleImage
    },
    blackLocust1: file(relativePath: { eq: "black-locust/false acacia in vorontsovi.jpg" }) {
      ...FluidArticleImage
    },
    blackLocust2: file(relativePath: { eq: "black-locust/IMG_9850 (1).jpg" }) {
      ...FluidArticleImage
    },
  }
`